<template>
  <div class="ManagerStatistics" >
    <formTitleComponent />
      <div class="container">
        <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
          <div class="text-center">
            <p>Une erreur s'est produite lors du chargement des informations de votre profil,
              veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
            </p>
            <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
          </div>
        </b-modal>

        <loading
          :active.sync="isLoading"
          :can-cancel="false"
          :is-full-page="true"
          color="#BB3634">
        </loading>
        <h1 class="form_div_title text-center">Espace administration manager - Statistiques année {{stakeYear}}</h1>
        <div class="form_div">
          <div class="form_content">

            <b-form-group id="year-group"
              label="Année étudiée"
              label-for="year-input">
              <b-form-select
                v-model="stakeYear"
                id="year-input"
                @change="getRemboursementsStatistics();getAccordsStatistics()"
                :options='yearsArray'>
              </b-form-select>
            </b-form-group>

            <div class="row">
              <div class="col-12">
                <h2 style="margin-top: 10px;">Montant alloués sur l'année {{stakeYear}}</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{formatThousandSeparatorNumber(nbRemboursementsJson['7MVALIDATED_MONTANT'])}} € </span><br>
                  <span class="badge_label">validés par le manager pour les frais de garde</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{formatThousandSeparatorNumber(nbRemboursementsJson['8PAIEMENT_MONTANT'])}} € </span><br>
                  <span class="badge_label">mis en paiement pour les frais de garde</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{formatThousandSeparatorNumber(nbRemboursementsJson['7MVALIDATED_MONTANT_FRAIS_DEPLACEMENT'])}} € </span><br>
                  <span class="badge_label">validés par le manager pour les frais supplémentaires</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{formatThousandSeparatorNumber(nbRemboursementsJson['8PAIEMENT_MONTANT_FRAIS_DEPLACEMENT'])}} € </span><br>
                  <span class="badge_label">mis en paiement pour les frais supplémentaires</span>
              </div>
            </div>
             <hr>



            <div class="row">
              <div class="col-12">
                <h2 style="margin-top: 10px;">Nombre de demandes d'accords {{stakeYear}} par état</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['2ONGOING_ACCORD']}}</span><br>
                  <span class="badge_label">à étudier</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['3INCOMPLET_ACCORD']}}</span><br>
                  <span class="badge_label">incomplètes</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['3IMPOTINCOMPLET_ACCORD']}}</span><br>
                  <span class="badge_label">incomplètes (avis d'imposition non suffisant)</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['4CREFUSED_ACCORD']}}</span><br>
                  <span class="badge_label">refusées par le conseiller</span>
              </div>
            </div>

            <div class="row mt-2">

              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['5CVALIDATED_ACCORD']}}</span><br>
                  <span class="badge_label">validées par le conseiller</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['6MREFUSED_ACCORD']}}</span><br>
                  <span class="badge_label">refusées par le manager</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbAccordsJson['7MVALIDATED_ACCORD']}}</span><br>
                  <span class="badge_label">validées par le manager</span>
              </div>
            </div>

            <hr>


            <div class="row">
              <div class="col-12">
                <h2 style="margin-top: 10px;">Nombre de demandes de remboursements par état en {{stakeYear}}</h2>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['2ONGOING_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">à étudier</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['3INCOMPLET_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">incomplètes</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['4CREFUSED_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">refusées par le conseiller</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['5CVALIDATED_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">validée par le conseiller</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['6MREFUSED_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">refusées par le manager</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['7MVALIDATED_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">validées par le manager</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['8PAIEMENT_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">mises en paiement</span>
              </div>
              <div class="col-md-3 col-sm-6 text-center">
                  <span class="badge_number">{{nbRemboursementsJson['DOUTE_REMBOURSEMENT']}}</span><br>
                  <span class="badge_label">en doute</span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import formTitleComponent from '@/components/formTitleComponent'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import renderMixins from '@/mixins/renderMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ManagerStatistics',
  components: {
    formTitleComponent,
    Loading,
    //remboursementsConseillerDashboardComponent
  },

  mixins: [
    renderMixins,
  ],

  data: function () {
    const now = new Date()
    const currentYear = now.getFullYear()
    return  {
      isAccordsStatisticsLoading: false,
      isRemboursementsStatsLoading: false,

      stakeYear: currentYear,

      nbAccordsJson: {
        '1CREATED_ACCORD': 0,
        '2ONGOING_ACCORD': 0,
        '3INCOMPLET_ACCORD': 0,
        '3IMPOTINCOMPLET_ACCORD': 0,
        '4CREFUSED_ACCORD': 0,
        '5CVALIDATED_ACCORD': 0,
        '6MREFUSED_ACCORD': 0,
        '7MVALIDATED_ACCORD': 0,
      },

      nbRemboursementsJson: {
        '1CREATED_REMBOURSEMENT': 0,
        '2ONGOING_REMBOURSEMENT': 0,
        '3INCOMPLET_REMBOURSEMENT': 0,
        '4CREFUSED_REMBOURSEMENT': 0,
        '5CVALIDATED_REMBOURSEMENT': 0,
        '6MREFUSED_REMBOURSEMENT': 0,
        '7MVALIDATED_REMBOURSEMENT': 0,
        '8PAIEMENT_REMBOURSEMENT': 0,
        "DOUTE": 0,

        '7MVALIDATED_MONTANT': 0,
        '8PAIEMENT_MONTANT':0,
        '7MVALIDATED_MONTANT_FRAIS_DEPLACEMENT': 0,
        '8PAIEMENT_MONTANT_FRAIS_DEPLACEMENT':0
      },

      yearsArray: [
        { text: '2020', value: 2020 },
        { text: '2021', value: 2021 },
        { text: '2022', value: 2022 },
        { text: '2023', value: 2023 },
        { text: '2024', value: 2024 },
        { text: '2025', value: 2025 },
      ],
    }

  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isRemboursementsStatsLoading || this.isAccordsStatisticsLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getRemboursementsStatistics()
    this.getAccordsStatistics()
  },
  methods: {

    getRemboursementsStatistics () {
      this.isRemboursementsStatsLoading = true
      apiManagerConnected.getRemboursementsStatistics(this.token, this.stakeYear)
        .then((result) => {
          this.nbRemboursementsJson = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.isRemboursementsStatsLoading = false
        })
    },

    getAccordsStatistics () {
      this.isAccordsStatisticsLoading = true
      apiManagerConnected.getAccordsStatistics(this.token, this.stakeYear)
        .then((result) => {
          this.nbAccordsJson = result.data
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.isAccordsStatisticsLoading = false
        })
    },
  }
}
</script>

<style>
.badge_number {
  font-size: 26px !important;
  font-weight: bold;
  color: #BB3634
}
.badge_label {
  font-size: 18px !important;
}
</style>
